<template>
  <validation-observer
    ref="paymentWalletValidator"
    slim
  >
    <div class="mt-2">
      <b-row class="mt-2 mb-2">
        <b-col class="balance-title" cols="9" align="left">
          {{ $t('general.actions.update') }} « {{ $t('components.form.client.details.body.right.balance.wallet') }} »
        </b-col>
        <b-col cols="3" align="right">
          <span @click="updatePaymentToken()" class="d-bg-gray">
            <i class="pointer fa fa-check"></i>
          </span>
          <span
            @click="$emit('on:leave')"
            class="d-bg-gray">
            <i class="pointer fa fa-close"></i>
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          {{ $t('components.form.client.details.body.right.balance.balance-wallet') }}
        </b-col>
        <b-col cols="12">
          <d-text-field
            v-model="wallet"
            class-name="background-light-blue-inputs"
            type="number"
            size="sm"
            :rules="validation.balance"
            error-text="validation.required"
          />
        </b-col>
        <b-col cols="12">
          {{ $t('components.form.client.details.body.right.balance.note') }}
        </b-col>
        <b-col cols="12">
          <d-text-field
            v-model="labelOperation"
            class-name="background-light-blue-inputs"
            size="sm"
            :rules="validation.label"
            error-text="validation.required"
          />
        </b-col>
      </b-row>
    </div>
  </validation-observer>
</template>
<script>
import {getClientWallet, postClientWallet} from "@api/doinsport/services/client/wallet/wallet.api";
import Wallet from "@/classes/doinsport/Wallet";
import {postWalletOperation} from "@api/doinsport/services/client/wallet/operation/operation.api";
import {fromIdToIriReference} from "@/utils/form";

export default {
  data: () => ({
    model: null,
    wallet: 0,
    labelOperation: null,
    validation: require('@validation/entities/doinsport/Operation.json')
  }),
  props: {
    mode: {
      type: String,
      default: 'edit'
    }
  },
  methods: {
    updatePaymentToken() {
      try {
        this.$refs.paymentWalletValidator.validate().then((valid) => {
          if (valid) {
            let balance = null;

            if (this.mode === 'create') {
              balance = parseFloat(this.wallet).toFixed(2);
            } else {
              balance = (this.wallet - (parseFloat(this.model.balance) / 100)).toFixed(2);
            }
            let toPost = null;

            if (this.mode === 'create') {
              toPost = {label: this.labelOperation, overdraftAuthorized: 10};
            } else {
              toPost = {label: this.labelOperation, wallet: this.model['@id'], overdraftAuthorized: 10};
            }

            if (balance > 0) {
              Object.assign(toPost, {credit: parseInt((balance * 100).toFixed(2))});
            } else {
              Object.assign(toPost, {debit: parseInt((Math.abs(balance) * 100).toFixed(2))});
            }
            if (this.mode === 'create') {
              postClientWallet({
                client: fromIdToIriReference('/clubs/clients', this.$route.params.id),
                operations: [toPost]
              })
                .then((response) => {
                  this.$emit('on:leave');
                })
              ;
            } else {
              postWalletOperation(toPost)
                .then((response) => {
                  this.$emit('on:leave');
                })
              ;
            }
          }
        })
      } catch (e) {

      }
    },
    loadClientWallet(clientId) {
      this.wallet = null;

      getClientWallet(clientId)
        .then((response) => {
          if (response.data['hydra:member'].length > 0) {
            this.model = response.data['hydra:member'][0];
            this.wallet = new Wallet(response.data['hydra:member'][0], {serialize: true}).balance;
          }
        })
      ;
    }
  },
  created() {
    this.loadClientWallet(this.$route.params.id);
  }
}
</script>
<style scoped>
.balance-title {
  font: normal normal 900 14px Avenir;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.d-bg-gray:hover {
  color: #707070;
}

/deep/ .has-calendar .form-control-feedback {
  line-height: 2.675rem;
}
</style>
